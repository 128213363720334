<template>
    <div class="page-test-app-date-range">
        <app-date-range
            v-model="date_range"
        />
    </div>
</template>

<script>
import appDateRange from '@/components/app-date-range'

export default {
    components: {
        appDateRange,
    },

    data() {
        return {
            /*
                date_range: {
                    startDate: null,
                    endDate: null,
                },
            */

            date_range: {
                startDate: new Date(2021, 3, 5),
                endDate:   new Date(2021, 3, 15),
            },
        }
    },
}
</script>

<style lang="scss">
.page-test-app-date-range {
    display: flex;
    justify-content: center;

    padding-top: 48px;

    .app-date-range {
        max-width: 300px;
    }
}
</style>